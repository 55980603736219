.FooterTitle {
    background-color: #494B68;
    color: rgb(226, 226, 226);  
    padding-top: 20px;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 3px;
    width: 100%;
    flex-direction: column;
    margin-bottom: 0px;
}

.Footer {
    background-color: #494B68;
    height: 150px;
    color: rgb(226, 226, 226);  
    display: flex;
    justify-content: space-evenly;
    padding-top: 10px;
    width: 50%;
    align-items: center;
    margin: auto;
}

.Footer a:hover {
    transform: scale(1.2); 
    transition: all .3s ease-in-out; 
}

@media screen and (max-width:768px) {
    .Footer {
        width: 80%;
    }
}   