.Global {
    display: flex;
    flex: auto;
    justify-content: center;
    margin-top: 20px;
    box-sizing: border-box;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: rgb(226, 226, 226);
    padding-bottom: 50px;
}


.Global img {
    border-radius: 10px;
    opacity: 0.8;
    height: 350px;
    max-width: 100%;
    margin: 10px;
}

.Global h4 {
    letter-spacing: 3px;
}

.Text {
    display: flex;
    width: 50%;
    padding-left: 30px;
    margin: 15px;
    text-align: left;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


@media screen and (max-width:768px) {
.Global {
    flex-direction: column;
    font-size: 14px;
}

.Text {
    width: 80%;
    padding-left: 0;
}

}   


