body {
  background-color: #1E4258;
  -webkit-text-size-adjust: 100%;
  display: flex;
  flex-direction: column;
}

html, body {
  height: 100%;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}


