.Global {
    margin-top: 60px;
    background-color: #494B68;
    height: 450px;
    margin-bottom: 50px;
    font-family: 'Poppins', sans-serif;
    color: rgb(226, 226, 226);  
    letter-spacing: 3px;
    width: 100%;
}

.Global a {
    text-decoration: none;
    color: rgb(226, 226, 226); 
}

.Global a:hover {
    color:#022140;
    transition: 0.7s ease;
}
