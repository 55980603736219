.Nav {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-height: 8vh;
    font-family: 'Poppins', sans-serif;
}

.Logo {
    color: rgb(226, 226, 226);
    text-transform: uppercase;
    letter-spacing: 5px;
    font-size: 20px;
}

.Logo a {
    color: rgb(226, 226, 226);
    text-decoration: none;
}

.Logo a:hover {
    color:#022140;
    transition: 0.5s ease;
}

.NavLinks {
    display: flex;
    justify-content: space-around;
    width: 60%;
}

.NavLinks li {
    list-style: none;
}

.NavLinks a {
    color: rgb(226, 226, 226);
    text-decoration: none;
    letter-spacing: 3px;
    font-weight: bold;
    font-size: 14px;
}

.NavLinks a:hover {
    color:#022140;
    transition: 0.5s ease;
}

.Burger {
    display: none;
    cursor: pointer;
}

.Burger div {
    width: 25px;
    height: 3px;
    background-color: rgb(226, 226, 226);
    margin: 5px;
    transition: all 0.3s ease;
}

@media screen and (max-width:1024px) {
    .NavLinks {
        width: 90%;
    }
}

@media screen and (max-width:768px) {
    html, body {
        overflow-x: hidden;
        height: 100%;
        position: fixed;
        width: 100%;    
    }
    
    .NavLinks {
        position: absolute;
        right: 0px;
        height: 92vh;
        top: 8vh;
        background-color: #2D5F5D;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        padding-top: 30px;
        width: 50%;
        transform: translateX(100%);
        transition: transform 0.5s ease-in;
        z-index: 1;
    }
    .NavLinks li {
        opacity: 0;
        animation: none;
        padding-bottom: 30px;
    }
    .Burger {
        display: block;
    }
}

.NavActive {
    transform: translateX(0%);
}

.NavActive li {
    animation: NavLinkFade 1.5s ease forwards 0.3s;
}


@keyframes NavLinkFade {
    from {
        opacity: 0;
        transform: translateX(50px);
    }
    to {
        opacity: 1;
        transform: translateX(0px);
    }
}

.Toggle .Line1 {
    transform: rotate(-45deg) translate(-5px,6px);
}

.Toggle .Line2 {
    opacity: 0;
}

.Toggle .Line3 {
    transform: rotate(45deg) translate(-5px,-6px);
}