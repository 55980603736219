.Global {
    font-family: 'Poppins', sans-serif;
    color: rgb(226, 226, 226);
    display: flex;
    justify-content: space-around;
    font-weight: bold;
    background-color: #2D5F5D;
    margin-bottom: 100px;
    padding: 20px;
}

.Global a {
    text-decoration: none;
    color: rgb(226, 226, 226);
}

.Global li {
    list-style: none;
    padding-bottom: 15px;
    font-weight: normal;
    margin: 0;
    text-align: left;
}

.Global li:hover {
    color:#022140;
    transition: 0.5s ease;
}

.Heading {
    margin-top: 60px;
    color: rgb(226, 226, 226);
    letter-spacing: 3px;
    width: 100%;
    flex: auto;
    font-weight: bold;
    font-size: 18px;
}


@media screen and (max-width:768px) {
    .Global {
        flex-direction: column;
    }

    .Heading {
        font-size: 16px;
    }

}