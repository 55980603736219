.GlobalOne {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    background-color: #2D5F5D;
    color: rgb(226, 226, 226);  
    text-align: left;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

.GlobalOne a {
    text-decoration: none;
    color: rgb(226, 226, 226);  
}

.GlobalOne a:hover {
    color:#022140;
    transition: 0.5s ease;
}

.GlobalTwo {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    background-color: #022140;
    color: rgb(226, 226, 226);  
    text-align: left;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

.GlobalTwo a {
    text-decoration: none;
    color: rgb(226, 226, 226);  
}

.GlobalTwo a:hover {
    color:#2D5F5D;
    transition: 0.5s ease;
}

.ParStyle {
    width: 50%;
    text-align: left;

}

.ParStyleTwo {
    width: 50%;
    text-align: left;
}

@media screen and (max-width:768px) {
    .GlobalOne {
        flex-direction: column;
        width: 100%;
        margin: 0;
        box-sizing: border-box;
    }

    .GlobalTwo {
        flex-direction: column;
        width: 100%;
        margin: 0;
        box-sizing: border-box;
    }

    .ParStyle {
        width: 80%;
        text-align: center;
        padding-left: 0;
    }

    .ParStyleTwo {
        width: 80%;
        text-align: center;
        padding-left: 0;
    }
}   