.Global {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 60px;
    padding-bottom: 50px;
}

.Download{
    background-color: #494B68;
    text-decoration: none;
    color: rgb(226, 226, 226);
    font-weight: bold;
    padding: 15px;
    margin-bottom: 50px;
    border-radius: 10px;
}

.Global a:hover {
    color:#022140;
    transition: 0.7s ease;
}


@media screen and (max-width:768px) {

    .Global {
        padding-bottom: 20px;
    }
}   